// @ts-nocheck

import React, { lazy, Suspense, useContext, useState, useEffect } from 'react';

import App from './App';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import useUserStore from 'stores/UserStore';
const Loading = lazy(() => import('atoms/Loading/Loading'));
export default function AppWrapper() {
	const UserStore = useUserStore((s) => s);

	return (
		<>
			<div
				style={{
					minHeight: '100vh',
					margin: '0 auto',
					width: '100%',
				}}
			>


				<Notifications position="top-center" zIndex={1000} autoClose={5000} />
				<App />
				<Loading />
			</div>
		</>
	);
}
