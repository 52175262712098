import { API } from 'utils/axios';
import moment from "moment-timezone";

export const getBusinessVenue = async ({
    businessId,
}) => {
    const command = 'get-business-venue';
    const data = {
        cmd: command,
        "business_id": businessId,
    };
    return await API({ data })
};
export const getBusinessVenues = async (businessId) => {
    const command = 'get-business-venues';
    const data = {
        cmd: command,
    };
    return await API({ data })
};
export const getBusinessVenuesBySearch = async ({ search }) => {
    const command = 'get-business-venues_by_search';
    const data = {
        cmd: command,
        search
    };
    return await API({ data })
};

export const updateBusinessVenue = async ({
    id,
    ...keysValues
}) => {
    const command = 'update-business-venue';
    const data = {
        cmd: command,
        id,
        updateAttrVal: {
            ...keysValues
        }
    };
    return await API({ data });
}
export const updateBusinessVenueLogo = async ({
    id,
    imageFile
}) => {
    const command = 'update-business-venue-logo';
    const data = {
        cmd: command,
        id,
        logo_image: imageFile,
        dbofields: []
    };
    console.log("sending data", data)
    return await API({ data }).then(res => {
        console.log(res)
        return res
    })

}
export const addBusinessVenueImage = async ({
    id,
    image
}) => {
    const command = 'add-business-venue-image';
    const data = {
        cmd: command,
        venue_id: id,
        image,
        form_id: "dbofields",
    };
    return await API({ data });

}
export const removeBusinessVenueImage = async ({
    imageId,
    id,
}) => {
    const command = 'remove-business-venue-image';
    const data = {
        cmd: command,
        obj_id: imageId,
        venue_id: id
    };
    return await API({ data });

}
export const selectBusinessVenueImage = async ({
    imageId,
    id,
}) => {
    const command = 'select-business-venue-image';
    const data = {
        cmd: command,
        obj_id: imageId,
        venue_id: id
    };
    return await API({ data });

}

export const createVenue = async ({
    uid,
    venueData,
    venueImages = [],
    locationData = {},
    lat,
    lng
}) => {
    const command = 'handle-venue';

    let thisLocationData = {}
    if (Object.keys(locationData).length > 0) {
        thisLocationData = {
            venue_loc_name: locationData.venueTitle,
            venue_loc_address: locationData.address,
            venue_loc_postcode: locationData.postcode,
            venue_loc_state: locationData.state,
        }
    }

    console.log(thisLocationData, 'hello');

    const data = {
        action: "create",
        cmd: command,
        uid,
        dbofields: venueData,
        client_timezone: moment.tz.guess(),
        // venueId,
        obj_id: -1,
        lat,
        lng,
        ...thisLocationData
    };

    if (Object.keys(venueImages[0]).length > 0) {
        data["logo_image"] = venueImages[0].file;
    }

    return await API({ data });
};

// export const updateBusinessVenueImages = async ({
//     id,
//     ...imageFiles
// }) => {
//     const command = 'update-business-venue-images';
//     const data = {
//         cmd: command,
//         id,
//         dbofields: [],
//         ...imageFiles
//     };
//     console.log("sending data", data)
//     return await API({ data }).then(res => {
//         console.log(res)
//         return res
//     })

// }