import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { BesVendorUser } from 'types/BesVendorUser.type';
import { Auth } from 'types/Auth.type';
import { LocalStorage } from 'utils/localStorage';

//ts-
const STORE_KEY = "slb-auth-store";
type Store = {

    auth: Auth | {};
    isLogin: () => boolean;
    setState: (key: string, value: any) => void;
    resetState: () => void;
};
let initialState = {
    auth: {}
};
// @ts-ignore
initialState = LocalStorage.initStore(STORE_KEY, initialState)
const useAuthStore = create<Store>()(
    devtools(
        persist(
            (set, get) => ({
                ...initialState,
                isLogin: () => {
                    return Object.keys(get().auth).length > 0;
                },
                setState: (key, value) => {
                    set((s) => ({ [key]: value }));
                },
                resetState: () => {
                    set((s) => initialState);
                },
            }),
            {
                name: STORE_KEY,
                storage: createJSONStorage(() => localStorage),
                version: 1,
            }
        )
    )
);
export default useAuthStore;
