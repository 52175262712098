import { API } from 'utils/axios';
import moment from "moment-timezone";
import { EVENT_ADDITIONAL_INFO_ANSWER_TYPES } from "utils/constants/event";

export const createEvent = async ({
    uid,
    eventData,
    eventImages = [],
    locationData = {},
    eventStartDate,
    eventEndDate
}) => {
    const command = 'handle-event';

    let thisLocationData = {}
    if (Object.keys(locationData).length > 0) {
        thisLocationData = {
            event_loc_name: locationData.eventTitle,
            event_loc_address: locationData.address,
            event_loc_city: locationData.city,
            event_loc_postcode: locationData.postcode,
            event_loc_state: locationData.state,
        }
    }

    const data = {
        action: "create",
        cmd: command,
        uid,
        dbofields: eventData,
        client_timezone: moment.tz.guess(),
        // venueId,
        obj_id: -1,
        start_date: eventStartDate,
        end_date: eventEndDate,
        ...thisLocationData

    };
    if (Object.keys(eventImages[0]).length > 0) {
        data["image_wide"] = eventImages[0].file;
    }
    if (Object.keys(eventImages[1]).length > 0) {
        data["image_without_text"] = eventImages[1].file;
    }

    return await API({ data });
};
export const updateEvent = async ({
    uid,
    eventData,
    eventImages = [],
    locationData = {},
    eventStartDate,
    eventEndDate,
    id,
    ticketSaleStart,
    ticketSaleEnd
}) => {
    const command = 'handle-event';

    let thisLocationData = {}
    if (Object.keys(locationData).length > 0) {
        thisLocationData = {
            event_loc_name: locationData.eventTitle,
            event_loc_address: locationData.address,
            event_loc_city: locationData.city,
            event_loc_postcode: locationData.postcode,
            event_loc_state: locationData.state,
        }
    }

    const data = {
        action: "update",
        cmd: command,
        uid,
        dbofields: eventData,
        client_timezone: moment.tz.guess(),
        // venueId,
        obj_id: id,
        start_date: eventStartDate,
        end_date: eventEndDate,
        event_tickets_sale_start_date: ticketSaleStart,
        event_tickets_sale_end_date: ticketSaleEnd,
        ...thisLocationData

    };
    if (Object.keys(eventImages[0]).length > 0) {
        data["image_wide"] = eventImages[0].file;
    }
    if (Object.keys(eventImages[1]).length > 0) {
        data["image_without_text"] = eventImages[1].file;
    }

    return await API({ data });
};
export const deleteEvent = async ({
    id
}) => {
    const command = 'handle-event';
    const data = {
        action: "delete",
        cmd: command,
        obj_id: id,
    };

    return await API({ data });
};

export const getBusinessEvents = async ({ business_id }) => {
    const command = 'get-business-events';
    const data = {
        cmd: command,
        business_id: business_id
    };
    return await API({ data });
}
export const getEvent = async ({ eventId }) => {
    const command = 'get-event';
    const data = {
        cmd: command,
        id: eventId
    };
    return await API({ data });
}
export const updateEventStatus = async ({ eventId, isEffectiveNow, statusValue }) => {
    const command = 'update-event-status';
    const data = {
        cmd: command,
        id: eventId,
        is_effective_now: isEffectiveNow,
        status: statusValue
    };
    return await API({ data });
}
export const scheduleEventStatus = async ({
    eventId,
    hide,
    disabled,
    uid,
    scheduleDate,

}) => {
    const command = 'schedule-event-status';
    const data = {
        cmd: command,
        event_id: eventId,
        hide,
        disabled,
        vendor_user_id: uid,
        effective_date: scheduleDate,
        client_timezone: moment.tz.guess(),
    };
    return await API({ data });
}

export const createEventTicketType = async ({
    eventId,
    ticketTypeId,
    sessionId,
    title,
    description,
    cost,
    admits,
    cap,
    isSaleAvailability,
    max_per_customer,
    ticketSaleStart,
    ticketSaleEnd
}) => {
    const command = 'handle-event-ticket-type';
    const data = {
        cmd: command,
        obj_id: ticketTypeId,
        event_id: eventId,
        session_id: sessionId,
        action: "create",
        dbofields: {
            title,
            description,
            cost,
            admits,
            enabled_ticket_sale_availability: isSaleAvailability,
            cap,
            max_per_customer,
            'this-ticket-sale-start': ticketSaleStart,
            'this-ticket-sale-end': ticketSaleEnd,
        }

    };
    return await API({ data });
}
export const updateEventTicketType = async ({
    eventId,
    ticketTypeId,
    sessionId,
    title,
    description,
    cost,
    admits,
    cap,
    isSaleAvailability,
    max_per_customer,
    ticketSaleStart,
    ticketSaleEnd
}) => {
    const command = 'handle-event-ticket-type';
    const data = {
        cmd: command,
        obj_id: ticketTypeId,
        event_id: eventId,
        session_id: sessionId,
        action: "update",
        dbofields: {
            title,
            description,
            cost,
            admits,
            enabled_ticket_sale_availability: isSaleAvailability,
            cap,
            max_per_customer,
            'this-ticket-sale-start': ticketSaleStart,
            'this-ticket-sale-end': ticketSaleEnd,
        }

    };
    return await API({ data });
}
export const deleteEventTicketType = async ({
    id
}) => {
    const command = 'handle-event-ticket-type';
    const data = {
        cmd: command,
        obj_id: id,
        action: "delete"
    };
    return await API({ data });
}

export const createSessionAdditionalInfo = async ({
    title,
    required,
    inputType,
    numberOfOptions,
    options = [],
    sessionId,
    eventId
}) => {
    const command = 'handle-event-session-additional-info';
    const optionVal = {}
    if (Array.isArray(options)) {
        options?.forEach((x, i) => {
            optionVal[i + 1] = x;
        });

    }
    const data = {
        cmd: command,
        action: "create",
        dbofields: {
            title,
            required,
            type: EVENT_ADDITIONAL_INFO_ANSWER_TYPES.findIndex(x => x.name == inputType),
        },
        'option-num': numberOfOptions,
        optionVal,
        form_id: "dbofields",
        obj_id: -1,
        session_id: sessionId,
        event_id: eventId,
    };
    return await API({ data });
}
export const updateSessionAdditionalInfo = async ({
    title,
    required,
    inputType,
    numberOfOptions,
    options,
    sessionId,
    eventId,
    id
}) => {
    const command = 'handle-event-session-additional-info';
    const optionVal = {}
    if (Array.isArray(options)) {
        options?.forEach((x, i) => {
            optionVal[i + 1] = x;
        });
    }
    const data = {
        cmd: command,
        action: "update",
        dbofields: {
            title,
            required,
            type: EVENT_ADDITIONAL_INFO_ANSWER_TYPES.findIndex(x => x.name == inputType),
        },
        'option-num': numberOfOptions,
        optionVal,
        form_id: "dbofields",
        obj_id: id,
        session_id: sessionId,
        event_id: eventId,
    };
    return await API({ data });
}
export const deleteSessionAdditionalInfo = async ({
    id
}) => {
    const command = 'handle-event-session-additional-info';
    const data = {
        cmd: command,
        action: "delete",
        obj_id: id,
    };
    return await API({ data });
}
export const createSessionPromoCode = async ({
    title, code, promoType, promoAmount, ticketId, cap, sessionId
}) => {
    const command = 'handle-event-session-promo-code';
    const data = {
        cmd: command,
        action: "create",
        dbofields: {
            title,
            promo_code: code,
            type: promoType,
            value: promoAmount,
            tickets: ticketId,
            cap,
        },
        form_id: "dbofields",
        obj_id: -1,
        session_id: sessionId
    };
    return await API({ data });
}
export const updateSessionPromoCode = async ({
    id, title, code, promoType, promoAmount, ticketId, cap, sessionId
}) => {
    const command = 'handle-event-session-promo-code';
    const data = {
        cmd: command,
        action: "update",
        dbofields: {
            title,
            promo_code: code,
            type: promoType,
            value: promoAmount,
            tickets: ticketId,
            cap,
        },
        form_id: "dbofields",
        obj_id: id,
        session_id: sessionId
    };
    return await API({ data });
}
export const deleteSessionPromoCode = async ({
    id
}) => {
    const command = 'handle-event-session-promo-code';
    const data = {
        cmd: command,
        action: "delete",
        obj_id: id,
    };
    return await API({ data });
}
export const exportSessionPurchasedTickets = async ({
    sessionId
}) => {
    const command = 'purchased-tickets-export-to-csv';
    const data = {
        cmd: command,
        id: sessionId
    };
    return await API({ data });
}

export const getEventChartsData = async ({
    eventId
}) => {
    const command = 'get-event-charts-data';
    const data = {
        cmd: command,
        event_id: eventId,
    };
    return await API({ data });
}