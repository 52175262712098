import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { LocalStorage } from 'utils/localStorage';
import * as EventAPI from "services/event";
import useUserStore from './UserStore';

//ts-
const STORE_KEY = "wo-events-store";

interface Event {
    id?: string | number,
    [key: string | number]: any
}
type Store = {
    events: Event[],
    loadEvents: ({ businessId }: {
        businessId: number | string
    }) => void;
    setState: (key: string, value: any) => void;
    updateEvent: (id: string | number, value: any) => void;
    getEvent: (id: string | number) => Event;
    getEventSession: (eventId: number | string, sessionId: string | number) => any;
    resetState: () => void;
};
let initialState = {
    events: []
};
// @ts-ignore
// initialState = LocalStorage.initStore(STORE_KEY, initialState)

const useEventsStore = create<Store>()(
    // devtools(
    //     persist(
    (set, get) => ({
        ...initialState,
        loadEvents: async ({ businessId }) => {

            const { data } = await EventAPI.getBusinessEvents({
                business_id: businessId
            });
            get().setState("events", data.data?.events)
        },
        setState: (key, value) => {
            set((s) => ({ [key]: value }));
        },
        updateEvent: (id, value) => {

            set((s) => {
                let index = -1;
                const events = s.events;
                const event = s.events.find((x, i) => {
                    index = i;
                    return x.id == id;
                })
                events[index] = { ...event, ...value }
                console.log("update event 12", events[index])
                return { events }

            });
        },
        getEvent: (id) => {
            const event = get().events.find(x => x.id == id) || {}
            return event;
        },
        getEventSession: (eventId, sessionId) => {
            const event = get().getEvent(eventId)
            // @ts-ignore
            const session = event.sessions.find((x) => x.id == sessionId)
            return session;
        },
        resetState: () => {
            set((s) => initialState);
        },
    }),
    //         {
    //             name: STORE_KEY,
    //             storage: createJSONStorage(() => localStorage),
    //             version: 1,
    //         }
    //     )
    // )
);
export default useEventsStore;
