import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { BesVendorUser } from 'types/BesVendorUser.type';
import { Auth } from 'types/Auth.type';
import { LocalStorage } from 'utils/localStorage';
import * as BusinessAPI from "services/business";

//ts-
const STORE_KEY = "slb-account-business-store";

interface Business {
    id?: string | number,
    [key: string | number]: any
}

type Store = {
    business: {};
    setState: (key: string, value: any) => void;
    loadBusiness: ({ businessId }: {
        businessId: number | string
    }) => void;
    getBusiness: () => Business;
    resetState: () => void;
};
let initialState = {
    business: {}
};
// @ts-ignore

const useAccountBusinessStore = create<Store>()(
    (set, get) => ({
        ...initialState,
        loadBusiness: async ({ businessId }) => {
            const { data } = await BusinessAPI.getBusiness({
                business_id: businessId
            });
            get().setState("business", data.data?.business);
            return data;
        },
        setState: (key, value) => {
            set((s) => ({ [key]: value }));
        },
        getBusiness: () => {
            const business = get().business || {}
            return business;
        },
        resetState: () => {
            set((s) => initialState);
        },
    }),
);
export default useAccountBusinessStore;
