import {
    useReducer,
    useMemo,
    useEffect,
    useCallback,
    createContext,
    useRef,
} from 'react';
import coreReducer from 'reducers/coreReducer';
export const SignupContext = createContext({});

interface SignupProviderStateTypes {
    navbar: object,
    signupForm: {
        account: "personal" | "venue" | "organisation",
        personal: object,
        business: object,
        images: object,
    }
    completedSections: number[],
}

const SignupProvider = (props: any) => {
    const isMounted = useRef(false);
    const initialState: SignupProviderStateTypes = {
        navbar: {
            show: true,
            activeTab: 0
        },
        signupForm: {
            account: "organisation",
            personal: {},
            business: {},
            images: {},
        },
        completedSections: [],
    };
    const [state, dispatch] = useReducer(coreReducer, initialState);
    const { navbar, signupForm, completedSections } = state;

    const setSignupData = useCallback(
        (key: string, value: any) => {
            console.log(value, 'da value');
            dispatch({
                type: 'SET_STATE',
                key,
                payload: { value },
            });
        },
        [dispatch]
    );

    const resetSignupState = useCallback(() => {
        dispatch({
            type: 'RESET_STATE',
            payload: { initialState },
        });
    }, [dispatch]);


    const contextValue = useMemo(
        () => {
            return {
                navbar,
                signupForm,
                completedSections
            }
        },
        [navbar, signupForm, completedSections]
    );
    return (
        <SignupContext.Provider
            value={{
                contextValue,
                setSignupData,
                resetSignupState,
            }}>
            {props.children}
        </SignupContext.Provider>
    );
};

export default SignupProvider;