

export const SERVER_URL = window.bookmy.server_url;
console.log("server url", SERVER_URL)
// export const SERVER_URL =
// 	'http://bookmy.project8.inscribecms.com/';
// 'https://demo.bookmy.com.au/';

export const BASE_API_URL = `${SERVER_URL}venue_admin_SLB/ajax.php`;

// export const BASE_API_URL = window.bookmy.url + "ajax.php";

export const DEFAULT_USER_META = {
	ap: 'besc',
	os: 2,
	av: '1.0.0',
	u: 0,
	d: 0,
	i: 'web',
};
export const APP_CODE = 'SLB';

export const STRIPE_SCRIPT_URL = 'https://js.stripe.com/v3/';

export const NO_NAVBAR_ROUTES = ['/login', '/resetpassword'];
export const BACK_NAV_ROUTES = [
	{
		route: '/services/',
	},
];
export const APP_PLATFORMS = [
	{
		name: 'slb-web-admin',
		value: "SLB Web Admin",
		title: "Smart Lock Booking Admin",
	},
	{
		name: 'slb-app',
		value: "SLB Mobile App",
		title: "Smart Lock Booking Mobile App",
	}
];
export const USER_GENDERS = [
	{
		name: "Male",
		value: "Male"
	},
	{
		name: "Female",
		value: "Female"
	},
	{
		name: "Other",
		value: "Other"
	}
]
export const FEEDBACK_CATEGORIES = [
	{
		name: 'feedback-improvement',
		value: 'Improvement',
	},
	{
		name: 'feedback-features',
		value: 'Features',
	},
	{
		name: 'feedback-bug-crash',
		value: 'Bug/Crash',
	},
];

//all permissions available
export const USER_PERMISSIONS = [
	{
		name: 'dashboard',
		label: 'Dashboard',
	},
	{
		name: 'bookings',
		label: 'Bookings',
	},
	{
		name: 'services',
		label: 'Services',
	},
	{
		name: 'accounts',
		label: 'Accounts',
	},
	{
		name: 'settings',
		label: 'Settings',
	},
	{
		name: 'salto',
		label: 'SALTO KS',
	},
	{
		name: 'customers',
		label: 'Customers'
	},
	{
		name: 'memberships',
		label: 'Memberships'
	},
	{
		name: 'staffs',
		label: 'Staff'
	}
];


export const APP_NAV_SECTIONS = [
	{
		label: 'Dashboard',
		route: '/dashboard',
		permissions: ['dashboard'],
		tab: 0
	},
	{
		label: 'Settings',
		route: '/settings',
		permissions: ['settings'],
		tab: 1
	},
	{
		label: 'Services',
		route: '/services',
		permissions: ['services'],
		tab: 2
	},
	{
		label: 'Accounts',
		route: '/accounts',
		permissions: ['accounts'],
		tab: 3
	},
	{
		label: 'Support',
		route: '/support',
		permissions: ['support'],
		tab: 4
	},
	{
		label: 'Resources',
		route: '/resources',
		permissions: ['resources'],
		tab: 5
	},
	{
		label: 'SALTO',
		route: '/salto',
		permissions: ['salto'],
		tab: 6
	},
	{
		label: 'Customers',
		route: '/customers',
		permissions: ['customers'],
		tab: 7
	},
	{
		label: 'Staff',
		route: '/staffs',
		permissions: ['staffs'],
		tab: 8
	},
	{
		label: 'Memberships',
		route: '/memberships',
		permissions: ['memberships'],
		tab: 9
	},
	// {
	// 	label: 'Advertisements',
	// 	route: '/advertisements',
	// 	permissions: ['advertisements'],
	// },
];