import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

type Store = {
    navbarSections: object,
    business: any,
    setState: (key: string, value: any) => void;
};
const initialState = {
    navbarSections: [],
    business: undefined,
};

const useNavbarStore = create<Store>()(
    // devtools(
    //     persist(
    (set, get) => ({
        ...initialState,
        setState: (key, value) => {
            console.log(key, value);
            set((s) => ({
                ...s,
                [key]: value,
            }));
        },
    }),
    //         {
    //             name: 'slb-global-store',
    //             version: 1,
    //         }
    //     )
    // )
);
export default useNavbarStore;
