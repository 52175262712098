import React, { memo, lazy, useTransition, useContext, useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import { AppShell, Navbar, Box, Flex } from '@mantine/core';
import Routes from './routes/routes';
import { NO_NAVBAR_ROUTES } from 'utils/constants/core';
import { useViewportSize } from '@mantine/hooks';
import * as VenueAPI from "services/venue";
import useGlobalStore from 'stores/GlobalStore';
import BusinessVenuesProvider from 'contexts/BusinessVenuesProvider';
import useAccountBusinessStore from 'stores/AccountBusinessStore';
import useUserStore from 'stores/UserStore';
import { NotifyError } from 'utils/notifications';
import * as RoleAPI from 'services/role';
import { getUserAppNavSections } from "utils/core";
import * as Venue from 'services/venue';
import { BusinessVenuesContext } from 'contexts/BusinessVenuesProvider';
import useNavbarStore from 'stores/NavbarStore';
import { useMediaQuery } from '@mantine/hooks';

const FloatingButton = lazy(() => import('atoms/Buttons/FloatingButton'));
// const SignupNavBar = lazy(() => import('atoms/NavBars/SignupNavBar'));
const AppCoreNavBar = lazy(() => import('atoms/NavBars/AppCoreNavBar'));
const MobileNavBar = lazy(() => import('atoms/NavBars/MobileNavBar'));

function App() {
	// const GlobalStore = useGlobalStore
	const isMounted = useRef(false);
	const [isShowNav, setIsShowNav] = useState(true);
	const location = useLocation();
	const [isPending, startTransition] = useTransition();
	// const { height: windowHeight, width: windowWidth } = useViewportSize();
	const navbarSections = useNavbarStore((state) => state.navbarSections);
	const business = useNavbarStore((state) => state.business);
	const isMobile = useMediaQuery(`(max-width: 700px)`);

	const onToggleNavBar = (force = false, forceState = true) => {
		startTransition(() => {
			const isOpen = force == true ? forceState : !isShowNav;
			// setAppData('navbar', { show: isOpen });
			setIsShowNav(isOpen);
		});
	};

	useEffect(() => {
	}, [])

	const NavBar = memo(() => {
		if (NO_NAVBAR_ROUTES.includes(location.pathname)) {
			return false;
		}

		// if (navbar.show) {
		switch (location.pathname) {
			case '/signup':
				// return <SignupNavBar />;
				break;

			default:
				return (
					<BusinessVenuesProvider><AppCoreNavBar thisBusiness={business} navbarSections={navbarSections} /></BusinessVenuesProvider>
				);
				break;
		}

		return false;
	});

	const MobileNavigationBar = memo(() => {

		if (NO_NAVBAR_ROUTES.includes(location.pathname)) {
			return false;
		}

		// if (navbar.show) {
		switch (location.pathname) {
			case '/signup':
				// return <SignupNavBar />;
				break;

			default:
				return (
					<BusinessVenuesProvider><MobileNavBar thisBusiness={business} navbarSections={navbarSections} /></BusinessVenuesProvider>
				);
				break;
		}
		return false;
	});

	return (
		<div className={styles['app']}>
			<AppShell
				navbarOffsetBreakpoint="lg"
				asideOffsetBreakpoint="lg"
				navbar={<NavBar />}
				header={<MobileNavigationBar />}
				style={{ position: 'relative' }}
				classNames={isMobile && {
					main: styles['app-shell-main']
				}}
			>
				<Box
					p={(isMobile || NO_NAVBAR_ROUTES.includes(location.pathname)) ? 0 : {
						lg: '50px 30px 50px 30px',
						md: '0px 10px 50px 20px',
						sm: '0px 10px 50px 10px',
						xs: '0px 10px 50px 10px'
					}}
					w={'100%'}
					h={'100%'}
				>
					<Routes />
				</Box>
				{/* {
					!NO_NAVBAR_ROUTES.includes(location.pathname) &&
					<>
						<Box display={{ xs: "block", lg: "none" }}>
							<FloatingButton onClick={() => onToggleNavBar()} />
						</Box>
					</>
				} */}
			</AppShell>
		</div>
	);
}

export default App;
