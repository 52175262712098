import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';
import { BesVendorUser } from 'types/BesVendorUser.type';
import { Auth } from 'types/Auth.type';
import { LocalStorage } from 'utils/localStorage';

//ts-
const STORE_KEY = "slb-user-store";
type Store = {
	user:
	| BesVendorUser
	| {
		[key: string]: any;
	};
	setState: (key: string, value: any) => void;
	updateUser: (value: any) => void;
	getUserProfilePic: () => string;
	resetState: () => void;
};
let initialState = {
	user: {},
};
// @ts-ignore
initialState = LocalStorage.initStore(STORE_KEY, initialState)
const useUserStore = create<Store>()(
	devtools(
		persist(
			(set, get) => ({
				...initialState,
				getUserProfilePic: () => {
					let url = get().user?.image_remote_url;
					if (url && `${url}`.trim() != '') {
						return url;
					}
					return require('assets/images/default-profile-picture.jpeg');
				},
				setState: (key, value) => {
					set((s) => ({ [key]: value }));
				},
				updateUser: (value) => {
					set((s) => {
						return {
							user: {
								...s.user,
								...value
							}
						};
					});
				},
				resetState: () => {
					set((s) => initialState);
				},
			}),
			{
				name: STORE_KEY,
				storage: createJSONStorage(() => localStorage),
				version: 1,
			}
		)
	)
);
export default useUserStore;
