import { APP_NAV_SECTIONS } from "utils/constants/core";
export const getUserAppNavSections = (accountType, permissions = [], businessPermissions = []) => {
    return APP_NAV_SECTIONS.filter((x) => {
        if (x.route == '/settings' && accountType == 'personal') {
            return false;
        }

        if (x.route == '/salto') {
            return (
                x.permissions.filter((x) => permissions?.includes('salto')).length > 0 && businessPermissions.includes('salto')
            )
        }

        return (
            x.permissions.filter((x) => permissions?.includes(x)).length > 0 || x.route == '/support' || x.route == '/resources'
        );
    });
}