import { API } from 'utils/axios';

export const getBusiness = async ({ business_id }) => {
    const command = 'get_business';
    const data = {
        cmd: command,
        business_id: business_id,
    };

    return await API({ data });
};